import domReady from '@wordpress/dom-ready';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

domReady(() => {
  const commentItems = document.querySelectorAll(
    '.socials-section__comment-item'
  );

  if (0 < commentItems.length) {
    commentItems.forEach((commentItem) => {
      gsap.to(commentItem, {
        scrollTrigger: {
          trigger: commentItem,
          pin: false,
          start: 'top bottom',
        },
        y: 0,
        opacity: '1',
      });
    });
  }
});
